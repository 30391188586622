import type { ListPage, MenuItem } from 'src/interfaces'
import { ApiKey, Bag, Tablet, FaqCategory, FaqEntry, User, Move, WorkoutTemplateCategory, WorkoutTemplate, WorkoutTemplateVariant, WorkoutTemplateRound, WorkoutTemplateExercise, Workout } from './nextround-models'
// trigger rebuild

export const config: MenuItem[] = [
  {
    name: 'ApiKey',
    listPage: {
      createMutation: 'generateApiKey',
      identifier: 'key',
      type: 'ApiKey',
      model: 'ApiKey',
      allowNew: true,
      search: true,
      fields: {
        id: true,
        key: true,
        createdAt: { formatting: 'date' },
        updatedAt: { formatting: 'date' },
      },
      editPage: {
        fields: {
          createdAt: { type: 'date', readOnly: true },
          updatedAt: { type: 'date', readOnly: true },
          key: { type: 'text', readOnly: true },
          /*
          role: {
            identifier: 'name',
            type: 'Role',
            model: 'Role',
            allowNew: false,
            fields: {
              reference: true,
              recordData: true,
              name: true,
              uuid: true,
              createdAt: true,
              updatedAt: true,
            },
            editPage: {
              fields: {
                reference: { type: 'text' },
                recordData: { type: 'checkbox' },
                name: { type: 'text' },
                uuid: { type: 'text' },
                createdAt: { type: 'date', readOnly: true },
                updatedAt: { type: 'date', readOnly: true },
              },
            },
          },
          */
        },
      },
    } as ListPage<ApiKey>,
  },
  {
    name: 'Boxing bags',
    listPage: {
      identifier: 'name',
      model: 'Bag',
      allowNew: false,
      search: true,
      fields: {
        reference: true,
        recordData: true,
        name: true,
        uuid: true,
        firmwareVersion: true,
        latestRebootCount: { component: 'StabilityIndicator' },
        latestWifiStrength: { formatting: 'decimal' },
        averageReceivedPackets: true,
        createdAt: { formatting: 'date' },
        updatedAt: { formatting: 'date' },
      },
      editPage: {
        fields: {
          reference: { type: 'text' },
          recordData: { type: 'checkbox' },
          name: { type: 'text' },
          uuid: { type: 'text' },
          punchTrigger: { type: 'number' },
          createdAt: { type: 'date', readOnly: true },
          updatedAt: { type: 'date', readOnly: true },
          bagType: { type: 'enum' },
          forceFactor: { type: 'float' },
        },
      },
      dialogComponents: ['BagFirmwareSelector', 'ReceivedPacketMetricsDownloader', { componentName: 'Log', width: '700px' }],
    } as ListPage<Bag>,
  },
  {
    name: 'Firmware versions',
    listPage: {
      identifier: 'name',
      model: 'Firmware',
      allowNew: true,
      fields: {
        name: true,
        description: true,
        id: true,
        createdAt: { formatting: 'date' },
        updatedAt: { formatting: 'date' },
      },
      editPage: {
        fields: {
          file: {
            identifier: 'id',
            required: true,
            model: 'File',
            allowNew: false,
            type: 'file',
            fields: {
              downloadLocation: true,
              originalName: true,
              contentType: true,
            },
          },
          name: { type: 'text' },
          description: { type: 'text' },
        },
      },
    } as ListPage<any>,
  },
  {
    name: 'Tablets',
    listPage: {
      identifier: 'reference',
      model: 'Tablet',
      allowNew: true,
      search: true,
      fields: {
        reference: true,
        // bag.name: true
        createdAt: { formatting: 'date' },
        updatedAt: { formatting: 'date' },
      },
      editPage: {
        fields: {
          reference: { type: 'text' },
          hasSubscription: { type: 'checkbox' },
          canCreateGroupWorkouts: { type: 'checkbox' },
          canSeeClubJoyGroupWorkouts: { type: 'checkbox' },
          bag: {
            identifier: 'name',
            type: 'Bag',
            model: 'Bag',
            allowNew: true,
            fields: {
              reference: true,
              recordData: true,
              name: true,
              uuid: true,
              createdAt: { formatting: 'date' },
              updatedAt: { formatting: 'date' },
            },
            editPage: {
              fields: {
                rank: { type: 'number' },
                reference: { type: 'text' },
                recordData: { type: 'checkbox' },
                name: { type: 'text' },
                uuid: { type: 'text' },
                createdAt: { type: 'date', readOnly: true },
                updatedAt: { type: 'date', readOnly: true },
              },
            },
          } as ListPage<Bag>,
          apiKey: {
            type: 'dropdownV2',
            searchable: false,
            editable: false,
            unlinkable: false,
            identifier: 'key',
          } as unknown as ListPage<any>,
          createdAt: { type: 'date', readOnly: true },
          updatedAt: { type: 'date', readOnly: true },
        },
      },
    } as ListPage<Tablet>,
  },
  {
    name: 'Challenges',
    listPage: {
      model: 'ChallengeTemplate',
      identifier: 'name',
      allowNew: true,
      fields: {
        title: true,
        description: true,
      },
      editPage: {
        fields: {
          title: { type: 'text' },
          description: { type: 'text' },
          successMessage: { type: 'text' },
          failMessage: { type: 'text' },
          constraints: {
            model: 'ChallengeConstraintTemplate',
            type: 'ChallengeConstraintTemplate',
            identifier: 'id',
            allowNew: true,
            fields: {
              min: true,
              max: true,
            },
            editPage: {
              fields: {
                min: { type: 'int' },
                max: { type: 'int' },
                type: { type: 'enum' },
                showInUI: { type: 'checkbox' },
              },
            },
          } as ListPage<any>,
        },
      },
    } as ListPage<any>,
  },
  {
    name: "FAQ's",
    listPage: {
      model: 'FaqCategory',
      allowNew: true,
      fields: {
        name: true,
        createdAt: { formatting: 'date' },
        updatedAt: { formatting: 'date' },
      },
      editPage: {
        fields: {
          name: { type: 'text' },
          entries: {
            identifier: 'question',
            type: 'FaqEntry',
            model: 'FaqEntry',
            allowNew: true,
            fields: {
              question: true,
              content: true,
              createdAt: { formatting: 'date' },
              updatedAt: { formatting: 'date' },
            },
            editPage: {
              fields: {
                question: { type: 'text' },
                content: { type: 'text' },
                createdAt: { type: 'date', readOnly: true },
                updatedAt: { type: 'date', readOnly: true },
              },
            },
          } as ListPage<FaqEntry>,
          createdAt: { type: 'date', readOnly: true },
          updatedAt: { type: 'date', readOnly: true },
        },
      },
    } as ListPage<FaqCategory>,
  },
  {
    name: 'Users',
    listPage: {
      model: 'User',
      type: 'User',
      identifier: 'email',
      allowNew: false,
      fields: {
        email: true,
        userName: true,
        name: true,
        createdAt: { formatting: 'date' },
        updatedAt: { formatting: 'date' },
      },
      editPage: {
        fields: {
          email: { type: 'text' },
          userName: { type: 'text' },
          name: { type: 'text' },
          pin: { type: 'text' },
          gender: { type: 'enum' },
          weightKg: { type: 'float' },
          // picture: { type: 'file' },
          privacyLevel: { type: 'enum' },
          notificationTypes: { type: 'enum', isArray: true },
          createdAt: { type: 'date', readOnly: true },
          updatedAt: { type: 'date', readOnly: true },
          hasSubscription: { type: 'checkbox' },
        },
      },
    } as ListPage<any>,
  },
  {
    name: 'Moves',
    listPage: {
      model: 'Move',
      allowNew: true,
      fields: {
        name: true,
        tip: true,
        createdAt: { formatting: 'date' },
        updatedAt: { formatting: 'date' },
      },
      editPage: {
        fields: {
          name: { type: 'text' },
          tip: { type: 'text' },
          // image: { type: 'file' },
          // video: { type: 'file' },
          createdAt: { type: 'date', readOnly: true },
          updatedAt: { type: 'date', readOnly: true },
        },
      },
    } as ListPage<Move>,
  },
  {
    name: 'Workout templates',
    listPage: {
      model: 'WorkoutTemplateCategory',
      allowNew: true,
      fields: {
        name: true,
        createdAt: { formatting: 'date' },
        updatedAt: { formatting: 'date' },
        rank: true,
      },
      editPage: {
        fields: {
          name: { type: 'text' },
          rank: { type: 'float' },
          templates: {
            model: 'WorkoutTemplate',
            identifier: 'name',
            type: 'WorkoutTemplate',
            allowNew: true,
            fields: {
              name: true,
              // image: true,
              description: true,
              workoutType: true,
              createdAt: { formatting: 'date' },
              updatedAt: { formatting: 'date' },
              rank: true,
              duration: true,
            },
            editPage: {
              fields: {
                name: { type: 'text' },
                rank: { type: 'float' },
                duration: { type: 'int' },
                image: {
                  identifier: 'id',
                  queryFields: ['originalName', 'downloadLocation'],
                  model: 'File',
                  allowNew: false,
                  type: 'file',
                  fields: {
                    downloadLocation: true,
                    originalName: true,
                  },
                  editPage: {
                    fields: {
                      downloadLocation: { type: 'text', readOnly: true },
                      originalName: { type: 'text', readOnly: true },
                    },
                  },
                },
                overviewImage: {
                  identifier: 'id',
                  queryFields: ['originalName', 'downloadLocation'],
                  model: 'File',
                  allowNew: false,
                  type: 'file',
                  fields: {
                    downloadLocation: true,
                    originalName: true,
                  },
                  editPage: {
                    fields: {
                      downloadLocation: { type: 'text', readOnly: true },
                      originalName: { type: 'text', readOnly: true },
                    },
                  },
                },
                videoUrl: { type: 'text' },
                videoFile: {
                  identifier: 'id',
                  queryFields: ['originalName', 'downloadLocation'],
                  model: 'File',
                  allowNew: false,
                  type: 'file',
                  fields: {
                    downloadLocation: true,
                    originalName: true,
                  },
                  editPage: {
                    fields: {
                      downloadLocation: { type: 'text', readOnly: true },
                      originalName: { type: 'text', readOnly: true },
                    },
                  },
                },
                description: { type: 'text' },
                workoutType: { type: 'enum' },
                variants: {
                  model: 'WorkoutTemplateVariant',
                  type: 'WorkoutTemplateVariant',
                  identifier: 'id',
                  allowNew: true,
                  fields: {
                    difficultyLevel: true,
                    createdAt: { formatting: 'date' },
                    updatedAt: { formatting: 'date' },
                  },
                  editPage: {
                    fields: {
                      difficultyLevel: { type: 'enum' },
                      rounds: {
                        model: 'WorkoutTemplateRound',
                        type: 'WorkoutTemplateRound',
                        identifier: 'breakSeconds',
                        allowNew: true,
                        fields: {
                          breakSeconds: true,
                          countdownSeconds: true,
                          createdAt: { formatting: 'date' },
                          updatedAt: { formatting: 'date' },
                        },
                        editPage: {
                          fields: {
                            breakSeconds: { type: 'int' },
                            countdownSeconds: { type: 'int' },
                            videoUrl: { type: 'text' },
                            videoFile: {
                              identifier: 'id',
                              queryFields: ['originalName', 'downloadLocation'],
                              model: 'File',
                              allowNew: false,
                              type: 'file',
                              fields: {
                                downloadLocation: true,
                                originalName: true,
                              },
                              editPage: {
                                fields: {
                                  downloadLocation: { type: 'text', readOnly: true },
                                  originalName: { type: 'text', readOnly: true },
                                },
                              },
                            },
                            image: {
                              identifier: 'id',
                              queryFields: ['originalName', 'downloadLocation'],
                              model: 'File',
                              allowNew: false,
                              type: 'file',
                              fields: {
                                downloadLocation: true,
                                originalName: true,
                              },
                              editPage: {
                                fields: {
                                  downloadLocation: { type: 'text', readOnly: true },
                                  originalName: { type: 'text', readOnly: true },
                                },
                              },
                            },
                            exercises: {
                              model: 'WorkoutTemplateExercise',
                              type: 'WorkoutTemplateExercise',
                              identifier: 'seconds',
                              allowNew: true,
                              fields: {
                                breakSeconds: true,
                                calories: true,
                                seconds: true,
                                createdAt: { formatting: 'date' },
                                updatedAt: { formatting: 'date' },
                              },
                              editPage: {
                                fields: {
                                  breakSeconds: { type: 'int' },
                                  calories: { type: 'int' },
                                  seconds: { type: 'int' },
                                  videoUrl: { type: 'text' },
                                  videoFile: {
                                    identifier: 'id',
                                    queryFields: ['originalName', 'downloadLocation'],
                                    model: 'File',
                                    allowNew: false,
                                    type: 'file',
                                    fields: {
                                      downloadLocation: true,
                                      originalName: true,
                                    },
                                    editPage: {
                                      fields: {
                                        downloadLocation: { type: 'text', readOnly: true },
                                        originalName: { type: 'text', readOnly: true },
                                      },
                                    },
                                  },
                                  image: {
                                    identifier: 'id',
                                    queryFields: ['originalName', 'downloadLocation'],
                                    model: 'File',
                                    allowNew: false,
                                    type: 'file',
                                    fields: {
                                      downloadLocation: true,
                                      originalName: true,
                                    },
                                    editPage: {
                                      fields: {
                                        downloadLocation: { type: 'text', readOnly: true },
                                        originalName: { type: 'text', readOnly: true },
                                      },
                                    },
                                  },
                                  challenges: {
                                    model: 'ChallengeTemplate',
                                    type: 'ChallengeTemplate',
                                    identifier: 'title',
                                    allowNew: false,
                                    fields: {
                                      title: true,
                                      description: true,
                                    },
                                    editPage: {
                                      fields: {},
                                    },
                                  } as ListPage<any>,
                                  moves: {
                                    model: 'Move',
                                    type: 'Move',
                                    identifier: 'name',
                                    allowNew: false,
                                    fields: {
                                      name: true,
                                      tip: true,
                                    },
                                    editPage: {
                                      fields: {},
                                    },
                                  } as ListPage<any>,
                                  createdAt: { type: 'date', readOnly: true },
                                  updatedAt: { type: 'date', readOnly: true },
                                },
                              },
                            } as ListPage<any>,
                            createdAt: { type: 'date', readOnly: true },
                            updatedAt: { type: 'date', readOnly: true },
                          },
                        },
                      } as ListPage<any>,
                      createdAt: { type: 'date', readOnly: true },
                      updatedAt: { type: 'date', readOnly: true },
                    },
                  },
                } as ListPage<any>,
                createdAt: { type: 'date', readOnly: true },
                updatedAt: { type: 'date', readOnly: true },
              },
            },
          } as ListPage<any>,
          createdAt: { type: 'date', readOnly: true },
          updatedAt: { type: 'date', readOnly: true },
        },
      },
    } as ListPage<any>,
  },
  {
    name: 'Workouts',
    listPage: {
      model: 'Workout',
      allowNew: false,
      fields: {
        name: true,
        rating: true,
        workoutType: true,
        difficultyLevel: true,
        // user.name: true,
        createdAt: { formatting: 'date' },
        updatedAt: { formatting: 'date' },
      },
      editPage: {
        fields: {
          name: { type: 'text' },
          rating: { type: 'text' },
          notes: { type: 'text' },
          workoutType: { type: 'enum' },
          difficultyLevel: { type: 'enum' },
          // user.name: {type: "text", readOnly: true},
          createdAt: { type: 'date', readOnly: true },
          updatedAt: { type: 'date', readOnly: true },
        },
      },
    } as ListPage<Workout>,
  },
]

// Boxing bags

// - /bag/: List of bags
//   - reference
//   - recordData
//   - name
//   - uuid
//   - createdAt
//   - updatedAt

// - /bag/<id> Edit bag
//   - reference: text
//   - recordData: checkbox
//   - name: text (readOnly)
//   - uuid: text (readOnly)
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// Tablets

// - /tablet/: List of tablets (+new button)
//   - reference
//   - createdAt
//   - updatedAt

// - /tablet/<id>: Edit tablet
//   - reference: text
//   - bag: dropdown
//   - apiKey: text (readOnly)
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// FAQs

// - /faq-category/: List of categories (+new button)
//   - name
//   - createdAt
//   - updatedAt

// - /faq-category/<id>
//   - name: text
//   - entries: table (+new button)
//     - question
//     - createdAt
//     - updatedAt
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// - /faq/<id>
//   - question: text
//   - content: text
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// Users

// - /user/: List of users (NO new button)
//   - email
//   - userName
//   - name
//   - createdAt
//   - updatedAt

// - /user/<id>
//   - email: text
//   - userName: text
//   - name: text
//   - pin: text
//   - gender: dropdown (enum)
//   - weightKg: float
//   - picture: file
//   - privacyLevel: dropdown (enum)
//   - notificationTypes: dropdown (enum, multi-select)
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// Moves

// - /move/: List of moves
//   - name
//   - tip
//   - createdAt
//   - updatedAt

// - /move/<id>
//   - name: text
//   - tip: text
//   - image: file
//   - video: file
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// Workout template categories

// - /workout-template-category/: List of category (+new button)

// - /workout-template-category/<id>
//   - name: text
//   - templates: multi-select (many2many)

// - /workout-template/
//   - name
//   - image
//   - description
//   - workoutType
//   - createdAt
//   - updatedAt

// - /workout-template/<id>
//   - name: text
//   - image: file
//   - description: text
//   - workoutType: dropdown (enum)
//   - variants: table (+new button)
//     - difficultyLevel
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// - /workout-template-variant/<id>
//   - difficultyLevel: dropdown (enum)
//   - rounds: table (+new button)
//     - countdownSeconds
//     - breakSeconds
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// - /workout-template-round/<id>
//   - countdownSeconds: int
//   - breakSeconds: int
//   - exercises: table (+new button)
//     - breakSeconds
//     - seconds
//     - calories
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// - /workout-template-exercise/<id>
//   - breakSeconds: int
//   - seconds: int
//   - calories: int
//   - moves: multi-select (many2many)
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)

// Workouts

// - /workout/: List of workouts (NO new)
//   - name
//   - rating
//   - workoutType
//   - difficultyLevel
//   - (user.userName)
//   - createdAt
//   - updatedAt

// - /workout/<id>
//   - name: text (readOnly)
//   - rating: int
//   - notes: text
//   - workoutType: dropdown (enum)
//   - difficultyLevel: dropdown (enum)
//   - (user.userName): text (readOnly)
//   - createdAt: date (readOnly)
//   - updatedAt: date (readOnly)
